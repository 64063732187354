@mixin btn {
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    font-weight: $font-weight-bold;
    @include media-breakpoint-down(md) {
        font-size: 1.25rem;
    }
}

@mixin btn-primary {
    @include btn;
}

@mixin btn-secondary {
    @include btn;
}

.btn-primary {
    @include btn-primary;
}

.btn-secondary {
    @include btn-secondary;
}
body {
    font-weight: $font-weight-base;
    font-size: 1.125rem;
    color: $color-text;
    font-family: $font-family-base;
    line-height: $line-height-base;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
    margin: 0;
}

h1 {
    font-size: 3.5rem;
    line-height: 1.23;
    font-weight: $font-weight-bold;
    margin-bottom: 2rem;
    @include media-breakpoint-down(md) {
        font-size: 1.5rem;
        margin-bottom: 0.54em;
    }
}

h2 {
    font-size: 1.625rem;
    line-height: 1.33;
    font-weight: $font-weight-bold;
    margin-bottom: 1.15em;
    @include media-breakpoint-down(md) {
        font-size: 1.5rem;
    }
}

p {
    margin: 0;
}

ul.checks {
    padding: 0;
    margin: 0 0 3.125rem;
    list-style: none;

    li {
        font-size: 1.25rem;
        padding-left: 1.875rem;
        margin-bottom: 1.5rem;
        position: relative;

        &:before {
            @include icon('icon-check', 1rem, $color-main);
            position: absolute;
            top: 0.35em;
            left: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 1.875rem;
        li {
            font-size: 1rem;
            padding-left: 1.5rem;
            margin-bottom: 1.25rem;
            &:before {
                @include icon('icon-check', 0.8125rem, $color-main);
            }
        }
    }
}
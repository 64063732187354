header {
    color: $color-white;
    margin-top: 4rem;
    position: relative;
    z-index: 10;

    .heading {
        width: 100%;
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        h1, h2, p {
            color: $color-white;
        }
    }

    .images {
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        .image {
            width: 50%;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
            &:after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0,0,0,0.2);
            }

            &.left {
                &:before {
                    background: transparent linear-gradient(180deg, $color-main 0%, #00467500 100%) 0% 0% no-repeat padding-box;
                }
            }
            &.right {
                &:before {
                    background: transparent linear-gradient(180deg, $color-secondary 0%, #3E3C2C00 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 2.5rem;
    }
}
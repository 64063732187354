// Accessibility icons
@mixin icon($icon-name: 'arrow-right', $icon-size: 1em, $icon-color: $color-main) {
    content: '';
    mask: url('../images/icons/#{$icon-name}.svg') no-repeat 50% 50%;
    mask-size: contain;
    mask-position: right;
    display: inline-block;
    height: $icon-size;
    width: $icon-size;
    background-color: $icon-color;
    transition: $transition-hover;
}
img {
    max-width: 100%;
    height: auto;
}

main {
    display: flex;
    flex-wrap: wrap;

    .section-intro {
        width: 100%;
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
        text-align: center;
        position: relative;
        z-index: 10;

        h2 {
            color: $color-white;
        }

        p {
            color: $color-white;
            font-size: 1.25rem;
        }
    }

    .section-blocks {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        position: absolute;
        top: 0;
        bottom: 0;

        .block-wrapper {
            width: 50%;
            padding-top: 25rem;
            padding-bottom: 10rem;
            padding-left: $grid-gutter-width * 2;
            padding-right: $grid-gutter-width * 2;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            position: relative;

            @include media-breakpoint-up(md) {
                .image {
                    overflow: hidden;
                    position: relative;
                    .img-holder {
                        position:absolute; left:0; top:0; bottom:0; right:0;
                        transform: scale(1.1);
                        transition: transform 0.4s;
                        will-change: transform;
                        img {
                            backface-visibility: hidden;
                            transform: translateZ(0.1px);
                            transition: filter 0.4s;
                            will-change: transform;
                        }
                    }
                }
                &.hover {
                    .image {
                        .img-holder {
                            transform: scale(1.2);
                            img {
                                filter: blur(0.5rem);
                            }
                        }
                    }
                    .block {
                        &.blue-theme {
                            .btn {
                                box-shadow: -20px 40px 80px #00406D40;
                            }
                        }
                        &.grey-theme {
                            .btn {
                                box-shadow: -20px 40px 80px #2B2A1B40;
                            }
                        }
                    }
                }
            }

            .image {
                display: flex;
                flex-wrap: wrap;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 1;
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background: rgba(0, 0, 0, 0.2);
                    z-index: 1;
                }

                &.left {
                    &:before {
                        background: transparent linear-gradient(180deg, $color-main 0%, #00467500 100%) 0% 0% no-repeat padding-box;
                    }
                }

                &.right {
                    &:before {
                        background: transparent linear-gradient(180deg, $color-secondary 0%, #3E3C2C00 100%) 0% 0% no-repeat padding-box;
                    }
                }
            }
        }

        .block {
            max-width: 32rem;
            margin: 0 auto;
            background: #fff;
            padding: 3.125rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            &.clickable {
                cursor: pointer;
            }

            .logo {
                width: 22.5rem;
                height: 5rem;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 2.5rem;

                img {
                    width: 100%;
                }
            }

            .btn {
                margin-top: auto;
                width: 100%;
                display: block;
            }

            &.grey-theme {
                ul {
                    li {
                        &:before {
                            @include icon('icon-check', 1rem, $color-secondary);
                        }
                    }
                }
            }
        }
    }


    @include media-breakpoint-down(lg) {
        .section-blocks {
            .block {
                .logo {
                    width: 80%;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 3.75rem;
        position: relative;
        .section-intro {
            margin-top: 2.5rem;
            margin-bottom: 0;
            padding-bottom: 3.75rem;
            position: absolute;
            top: 100%;

            h2, p {
                color: $color-text;
            }
        }
        .section-blocks {
            position: relative;

            .block-wrapper {
                width: 100%;
                padding: 2.5rem;

                .block {
                    width: 100%;
                    padding: 1.875rem;

                    .logo {
                        width: 13.5rem;
                        height: auto;
                        margin-bottom: 1.875rem;
                    }
                }

                &.first {
                    padding-top: 6.6875rem;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .section-blocks {
            .block-wrapper {
                padding-left: 1.875rem;
                padding-right: 1.875rem;
            }
        }
    }
}